import React from "react"
import styles from "./style.module.scss"

export default function FormErrors({ errors, style }) {
  const textError = () => {
    if (errors.message !== "") {
      return errors.message
    }

    switch (errors.type) {
      case "required":
        return "Campo Requerido"
      case "minLength":
        return "Campo requiere una longitud mínima de 2"
      default:
        return errors.message
    }
  }

  return errors ? (
    <div className={styles.error} style={{ ...style }}>
      {textError()}
    </div>
  ) : null
}
