import cx from "classnames"
import dynamic from "next/dynamic"
import React, { createContext, isValidElement, useContext, useState } from "react"
import FormErrors from "src/components/FormErrors"
import FormField from "src/components/FormField"
import Input from "src/components/FormField/Input"
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect"

export const FormContext = createContext()

const Select = dynamic(() => import("src/components/FormField/Select"))
const CustomParamlistFieldset = dynamic(() => import("src/macrocomponents/CustomParamlistFieldset"))
const SingleDatePicker = dynamic(() => import("src/components/SingleDatePicker"))
const YesNoComponent = dynamic(() => import("semantic-ui-react/dist/commonjs/modules/Checkbox"))

const CustomSelect = (props) => {
  const [choices, setChoices] = useState(() => {
    if (typeof props.choices == "function") {
      const _choices = props.choices()
      if (typeof _choices?.then === "function") {
        _choices.then((res) => {
          if (typeof res.data === "object" && Array.isArray(res.data.records)) {
            setChoices(res.data.records)
          } else {
            setChoices(res.data)
          }
        })
        return null
      } else {
        return _choices
      }
    }

    return props.choices
  })

  return (
    <Select
      initialOptions={props.formatChoices(choices)}
      selectionChange={props.selectionChange}
      disabled={props.disabled}
      multiple={props.multiple}
      showOptionSelectedBelow={false}
      showAboutComponent
      {...props.componentProps}
    />
  )
}

const DependentSelect = (props) => {
  const [choices, setChoices] = useState(null)

  const { formValues, setValue } = useContext(FormContext)

  const dependsOn = Array.isArray(props.dependsOn) ? props.dependsOn : [props.dependsOn]

  const isEmptyValue = (v) => {
    return typeof v === "undefined" || v === null
  }

  const buildChoices = () => {
    if (typeof props.choices == "function") {
      if (dependsOn.some((v) => isEmptyValue(formValues[v]))) {
        setChoices([])
        return
      }
      const _choices = props.choices(Object.fromEntries(dependsOn.map((x) => [x, formValues[x]])))
      if (typeof _choices?.then === "function") {
        setChoices(null)
        _choices.then(({ data }) => {
          if (typeof data === "object" && Array.isArray(data.records)) {
            setChoices(data.records)
          } else {
            setChoices(data)
          }
        })
        return null
      }

      return _choices
    }

    return props.choices
  }

  const dependValues = dependsOn.map((x) => formValues[x])

  useDeepCompareEffectNoCheck(() => {
    if (typeof props.choices == "function") {
      setValue(props.name, null, { shouldValidate: true })
      setChoices(buildChoices())
    }
  }, dependValues)

  return (
    <Select
      initialOptions={props.formatChoices(choices)}
      selectionChange={props.selectionChange}
      disabled={props.disabled}
      multiple={props.multiple}
      showOptionSelectedBelow={false}
      showAboutComponent
      {...props.componentProps}
    />
  )
}

export const renderFormBody = ({
  fields,
  initialValues = {},
  values = {},
  errors = {},
  setValue = () => {},
  t,
  styles = null,
  options = {},
  readOnly = false
}) => {
  const isFieldRequired = (field) => {
    switch (typeof field.required) {
      case "undefined":
        return options.required ?? true
      case "function": {
        return field.required(values)
      }

      default:
        return field.required
    }
  }

  const renderFieldRow = (row) => {
    if (!Array.isArray(row)) {
      if (row?.componentType === "hidden") {
        return null
      }

      row = [row]
    }

    return (
      <div className={cx("fields", styles?.fields)}>
        {row.map((field, k) => {
          return <React.Fragment key={k}>{renderInput(field)}</React.Fragment>
        })}
      </div>
    )
  }

  const renderInput = (field) => {
    const isInputReadonly = readOnly ? true : field.disabled ?? false

    if (typeof field.visibility !== "undefined") {
      if (!field.visibility(values)) {
        return null
      }
    }

    if (field.content) {
      return <div className={cx("field field-content", field.className)}>{field.content}</div>
    }

    if (field.component) {
      if (isValidElement(field.component)) {
        return field.component
      } else {
        const Component = field.component
        return (
          <FormField
            label={t(field.label)}
            labelClassName={"form-label"}
            positionLabel={field.positionLabel ?? options.positionLabel ?? "up"}
            labelTooltip={field.labelTooltip}
            disabled={field.disabled}
            classRequired={!!errors[field.name]}
            required={isFieldRequired(field)}
            style={field.style}
            className={`fieldInput fieldInput${field.componentType ?? "default"}`}
            outline={false}
          >
            <Component
              disabled={isInputReadonly}
              initialValue={initialValues[field.name]}
              onChange={(value) => {
                setValue(field.name, value, { shouldValidate: true })
              }}
              {...field.componentProps}
            />
            <FormErrors errors={errors[field.name]} />
          </FormField>
        )
      }
    }

    switch (field.componentType) {
      case "hidden": {
        return null
      }

      case "fieldset": {
        return (
          <div className={cx("fieldset", field.className)}>
            <div className={cx("legend", field.legendClassname)}>{field.legend}</div>
            {renderFieldRow(field.fields)}
          </div>
        )
      }

      case "yesno": {
        return (
          <FormField
            label={t(field.label)}
            labelClassName={"form-label"}
            positionLabel={field.positionLabel ?? options.positionLabel ?? "up"}
            labelTooltip={field.labelTooltip}
            disabled={field.disabled}
            classRequired={!!errors[field.name]}
            required={isFieldRequired(field)}
            extraAlert={field?.extraAlert}
            className={"fieldYesNo"}
            style={field.style}
            outline={false}
          >
            <span
              style={{
                display: "inline-block",
                cursor: isInputReadonly ? "default" : "pointer",
                verticalAlign: "top",
                marginTop: 5,
                marginRight: 10,
                textTransform: "uppercase"
              }}
              onClick={() => setValue(field.name, 0, { shouldValidate: true })}
            >
              {t("main_ui.general.lb_no")}
            </span>
            <YesNoComponent
              checked={values[field.name] == 1}
              onChange={(ev, v) => setValue(field.name, v.checked ? 1 : 0, { shouldValidate: true })}
              disabled={isInputReadonly}
              toggle
            />
            <span
              style={{
                display: "inline-block",
                cursor: isInputReadonly ? "default" : "pointer",
                verticalAlign: "top",
                marginTop: 5,
                marginLeft: 10,
                textTransform: "uppercase"
              }}
              onClick={() => setValue(field.name, 1, { shouldValidate: true })}
            >
              {t("main_ui.general.lb_yes")}
            </span>
          </FormField>
        )
      }
      case "select": {
        let v = values[field.name]
        if (v && typeof v === "object" && typeof v.id != "undefined") {
          v = v.id
        }

        const formatChoices = (choices) => {
          if (choices === null) {
            return null
          }
          if (field.add_empty) {
            choices = [
              {
                id: "",
                label: field.add_empty === true ? "" : field.add_empty,
                selected: v === null || field.multiple ? v?.length == 0 : `${v}`.length === 0
              }
            ].concat(choices)
          }

          return choices?.map((choice) => {
            const label =
              typeof field.formatLabel === "function"
                ? field.formatLabel(choice)
                : typeof choice.label !== "undefined"
                ? t(choice.label + "")
                : choice.name || choice.nombre

            const id = typeof field.formatId === "function" ? field.formatId(choice) : choice.id

            return {
              ...choice,
              id,
              label,
              selected: v && Array.isArray(v) && field.multiple ? v?.includes(id) : id == v
            }
          })
        }

        return (
          <FormField
            label={t(field.label)}
            labelClassName={"form-label"}
            positionLabel={field.positionLabel ?? options.positionLabel ?? "up"}
            labelTooltip={field.labelTooltip}
            disabled={field.disabled}
            classRequired={!!errors[field.name]}
            required={isFieldRequired(field)}
            extraAlert={field?.extraAlert}
            className={"fieldSelect"}
            style={field.style}
          >
            <CustomSelect
              choices={field.choices}
              formatChoices={formatChoices}
              selectionChange={(value) => {
                if (field.multiple) {
                  setValue(
                    field.name,
                    value?.map((x) => x.id),
                    { shouldValidate: true }
                  )
                } else {
                  setValue(field.name, value.id, { shouldValidate: true })
                }
              }}
              disabled={isInputReadonly}
              multiple={field.multiple}
              componentProps={field.componentProps}
            />

            <FormErrors errors={errors[field.name]} />
          </FormField>
        )
      }

      case "dependentselect": {
        const v = values[field.name]
        const formatChoices = (choices) => {
          if (choices === null) {
            return null
          }
          if (field.add_empty) {
            choices = [
              {
                id: "",
                label: field.add_empty === true ? "" : field.add_empty,
                selected: v === null || field.multiple ? v?.length == 0 : `${v}`.length === 0
              }
            ].concat(choices)
          }

          return choices?.map((choice) => {
            const label =
              typeof field.formatLabel === "function"
                ? field.formatLabel(choice)
                : t(choice.label) || choice.name || choice.nombre

            return {
              ...choice,
              label,
              selected: v && Array.isArray(v) && field.multiple ? v?.includes(choice.id) : choice.id == v
            }
          })
        }

        return (
          <FormField
            label={t(field.label)}
            labelClassName={"form-label"}
            positionLabel={field.positionLabel ?? options.positionLabel ?? "up"}
            labelTooltip={field.labelTooltip}
            disabled={field.disabled}
            classRequired={!!errors[field.name]}
            required={isFieldRequired(field)}
            extraAlert={field?.extraAlert}
            className={"fieldSelect"}
            style={field.style}
          >
            <DependentSelect
              name={field.name}
              dependsOn={field.dependsOn}
              choices={field.choices}
              formatChoices={formatChoices}
              selectionChange={(value) => {
                if (field.multiple) {
                  setValue(
                    field.name,
                    value?.map((x) => x.id),
                    { shouldValidate: true }
                  )
                } else {
                  setValue(field.name, value.id, { shouldValidate: true })
                }
              }}
              disabled={isInputReadonly}
              componentProps={field.componentProps}
              multiple={field.multiple}
            />

            <FormErrors errors={errors[field.name]} />
          </FormField>
        )
      }
      case "checkbox": {
        let v = values[field.name] ?? []

        const updateCheckbox = (id) => {
          if (v.includes(id)) {
            v = v.filter((x) => x != id)
          } else {
            v.push(id)
          }

          return v
        }

        return (
          <FormField
            label={t(field.label)}
            labelClassName={"form-label"}
            positionLabel={field.positionLabel ?? options.positionLabel ?? "up"}
            labelTooltip={field.labelTooltip}
            disabled={field.disabled}
            classRequired={!!errors[field.name]}
            required={isFieldRequired(field)}
            className={"fieldCheckbox"}
            style={field.style}
            outline={field.styleOutline ?? false}
          >
            <div className='grouped fields'>
              {field.choices?.map((x) => {
                return (
                  <div className='field' key={x.id}>
                    <div
                      className='ui checkbox'
                      onClick={() => setValue(field.name, updateCheckbox(x.id), { shouldValidate: true })}
                    >
                      <input
                        type='checkbox'
                        checked={v?.includes(x.id)}
                        className='hidden'
                        disabled={isInputReadonly}
                      />
                      <label>{t(x.label)}</label>
                    </div>
                  </div>
                )
              })}
            </div>

            <FormErrors errors={errors[field.name]} />
          </FormField>
        )
      }

      case "textarea": {
        return (
          <FormField
            label={t(field.label)}
            labelClassName={"form-label"}
            positionLabel={field.positionLabel ?? options.positionLabel ?? "up"}
            labelTooltip={field.labelTooltip}
            disabled={field.disabled}
            classRequired={!!errors[field.name]}
            required={isFieldRequired(field)}
            style={field.style}
            className={`fieldInput fieldInput${field.componentType ?? "default"}`}
            outline={field.styleOutline ?? false}
          >
            <textarea
              disabled={isInputReadonly}
              defaultValue={initialValues[field.name]}
              onChange={(ev) => {
                setValue(field.name, ev.target.value, { shouldValidate: true })
              }}
              {...field.componentProps}
            />
            <FormErrors errors={errors[field.name]} />
          </FormField>
        )
      }

      case "singledatepicker":
        return (
          <FormField
            label={t(field.label)}
            labelClassName={"form-label"}
            positionLabel={field.positionLabel ?? options.positionLabel ?? "up"}
            labelTooltip={field.labelTooltip}
            disabled={field.disabled}
            classRequired={!!errors[field.name]}
            required={isFieldRequired(field)}
            style={field.style}
            className={`fieldInput fieldInput${field.componentType ?? "default"}`}
            outline={field.styleOutline ?? false}
          >
            <SingleDatePicker
              disabled={isInputReadonly}
              defaultValue={initialValues[field.name]}
              onChange={([date, dateStr]) => {
                setValue(field.name, dateStr, { shouldValidate: true })
              }}
              {...field.componentProps}
            />
            <FormErrors errors={errors[field.name]} />
          </FormField>
        )

      case "custom_paramlists":
        return (
          <CustomParamlistFieldset
            scope={field.scope}
            cols={field.cols ?? 1}
            labelClassName={"form-label"}
            positionLabel={field.positionLabel ?? options.positionLabel ?? "up"}
            initialValues={values?.custom_paramlists ?? {}}
            className={"field fieldsetCustomParamlists"}
            disabled={isInputReadonly}
            onChange={(field, value) => setValue(`custom_paramlists.${field}`, value, { shouldValidate: true })}
          />
        )

      default:
        return (
          <FormField
            label={t(field.label)}
            labelClassName={"form-label"}
            positionLabel={field.positionLabel ?? options.positionLabel ?? "up"}
            labelTooltip={field.labelTooltip}
            disabled={field.disabled}
            classRequired={!!errors[field.name]}
            required={isFieldRequired(field)}
            style={field.style}
            className={`fieldInput fieldInput${field.componentType ?? "default"}`}
            outline={field.styleOutline ?? false}
          >
            <Input
              disabled={isInputReadonly}
              initialValue={initialValues[field.name]}
              cleanZero={false}
              onChange={(value) => {
                setValue(field.name, value, { shouldValidate: true })
              }}
              {...field.componentProps}
            />
            <FormErrors errors={errors[field.name]} />
          </FormField>
        )
    }
  }

  return (
    <FormContext.Provider value={{ fields, formValues: values, errors, setValue }}>
      {Array.isArray(fields)
        ? fields.map((field, k) => {
            return React.isValidElement(field) ? (
              <React.Fragment key={k}>{field}</React.Fragment>
            ) : (
              <React.Fragment key={k}>{renderFieldRow(field)}</React.Fragment>
            )
          })
        : fields}
    </FormContext.Provider>
  )
}
